@import 'theme/palette.scss';
@import 'theme/screen.scss';

.card {
  background-color: white;
  color: black;
  border-radius: 16px;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);

  @include not-phone {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  gap: 0px 0px;

  overflow: hidden;
  font-size: larger;
  text-align: center;
  font-weight: 800;

  .title {
    margin-top: 16px;
    font-size: xx-large;
    vertical-align: middle;

    @include phone {
      margin-bottom: 16px;
    }
  }

  .playNow {
    margin-bottom: 16px;
    padding: 16px;
    justify-self: center;
    width: 75%;
    background-color: $green;
    color: $light-blue-800;
    text-transform: uppercase;
    border-radius: 16px;
  }

  .screenshot {
    @include not-phone {
      grid-area: 1 / 2 / 3 / 3;
    }

    width: 100%;
    background-color: $light-blue-200;
    justify-self: center;

    display: grid;

    > img {
      padding: 16px;
      align-self: center;
      justify-self: center;
    }
  }
}
