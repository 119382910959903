@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  background-color: $light-blue-300;
  display: flex;
  flex-direction: column;

  > * {
    flex-grow: 0;
  }

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.title {
  margin-bottom: 16px;
}

.innards {
  overflow: hidden;
  flex: 1 1 auto;
  width: 100%;

  display: flex;
  flex-direction: column;

  @include desktop {
    align-self: center;
    max-width: 1000px;
  }
}

.payTable {
  flex: 0 0 auto;

  @include not-phone {
    margin: 24px 64px;
  }
}

.cards {
  flex: 1 1 auto;

  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 20px;
  align-items: stretch;
  justify-items: stretch;

  padding: 8px;
  gap: 0px 8px;

  @include not-phone {
    padding: 24px;
    gap: 0px 24px;
  }
}
