@import 'theme/screen.scss';

$loser-brightness: 0.25;

.root {
  overflow: hidden;
  display: flex;

  justify-content: center;

  .insides {
    position: relative;
    aspect-ratio: 2.5 / 3.5;
    display: flex;
    overflow: hidden;
  }
}

.hold {
  position: absolute;

  top: 50%;
  left: 0;
  right: 0;

  font-family: 'Azeret Mono', monospace;

  font-size: small;
  @include not-phone {
    font-size: larger;
  }

  letter-spacing: 0.2rem;
  font-weight: 600;

  user-select: none;

  background-color: rgba(gold, 90%);
  color: black;
  text-align: center;
  padding: 6px;
}

.fade {
  animation: loser-fade 1s 0s ease-out;
  filter: brightness($loser-brightness);
}

@keyframes loser-fade {
  from {
    filter: brightness(1);
  }
  to {
    filter: brightness($loser-brightness);
  }
}

.input {
  cursor: pointer;
}
