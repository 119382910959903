$desktop-minimum-width: 1025px; // Matches app code getDesktopMinimumWidth()
$tablet-minimum-width: 768px; // Matches app code getTabletMinimumWidth()

@mixin desktop {
  @media (min-width: #{$desktop-minimum-width}) {
    @content;
  }
}

@mixin handheld {
  @media (max-width: #{$desktop-minimum-width - 1}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$tablet-minimum-width - 1}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-minimum-width}) and (max-width: #{$desktop-minimum-width - 1px}) {
    @content;
  }
}

@mixin not-phone {
  @media (min-width: #{$tablet-minimum-width}) {
    @content;
  }
}
