@import 'theme/palette.scss';

.root {
  padding: 5px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  background-color: black;
  column-gap: 4px;

  border-top: 2px solid $blue-100;

  > .element {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    row-gap: 4px;

    &:not(:first-child) {
      justify-items: end;
    }

    .caption {
      color: $light-blue-800;
    }

    .value {
      font-size: larger;
      color: white;
    }
  }
}
