@import 'theme/colours.scss';
@import 'theme/screen.scss';

.footer {
  background-color: $light-background;
  padding: 20px;

  position: relative;
  top: 90%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.footerLink {
  flex: 1 1 auto;
  align-self: auto;
}

.linkBar {
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px 32px;
  text-align: center;

  @include not-phone {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 64px 64px;
  }

  max-width: 80%;
  justify-items: center;

  a,
  a:visited,
  a:active,
  a:focus {
    color: $text-on-light-background;
    text-decoration: none;
  }
}

.footerButtonBar {
  margin-bottom: 20px;
}

.footerButton {
  width: 32px;
  height: 32px;
  margin: 0 8px;
  flex: 0 1 auto;
}
