@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  background: $blue-400;
  background: linear-gradient(90deg, rgba($blue-400, 1) 0%, rgba($blue-400, 0.6) 100%);
  padding: 16px 16px 26px;
  filter: drop-shadow(0 0 0.5rem $blue-300);
}

.title {
  margin: 0 0 32px;

  font-size: x-large;
  @include not-phone {
    font-size: 1.5em;
  }
}
