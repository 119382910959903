@import 'theme/palette.scss';

.root {
  color: $blue-600;
  background-color: white;
  padding: 16px;

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 16px 0 0;

    button {
      cursor: pointer;
      padding: 8px;
      border: none;
      color: $white;
      background-color: $blue;
    }
  }
}
