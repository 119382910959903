// https://coolors.co/406187
$blue: #406187;

// Shades

$blue-100: #3b5a7e;
$blue-200: #324c6a;
$blue-300: #2d4561;
$blue-400: #24374d;
$blue-500: #1b2a3a;
$blue-600: #121c27;
$black: #000;

// Tints

$light-blue-200: #476c96;
$light-blue-300: #4e77a5;
$light-blue-400: #5982b1;
$light-blue-500: #7799bf;
$light-blue-600: #95afcd;
$light-blue-700: #b3c6db;
$light-blue-800: #f0f4f8;
$white: #fff;

// Hues

$green: #408757;
$purple: #744087;
