@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  display: grid;

  grid-template-columns: repeat(2, 1fr);

  @include not-phone {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }

  grid-gap: 1rem;
}

.card {
  background-color: white;
  color: $blue-600 !important;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;

  text-align: center;
  font-weight: 800;

  img {
    width: 100%;
    margin-bottom: 16px;
    object-fit: contain;
  }
}
