@import 'theme/palette.scss';

.overlay {
  color: $blue-600;
  background-color: rgba($blue-600, 90%);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);

  .modal {
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0;
    padding: 24px;

    h1 {
      margin: 0;
    }

    margin: 8px;

    @media (min-width: 768px) {
      top: 0;
      right: 0;
      // On the chance it grows to be very long, at least don't let it touch the sides
      margin: 64px;

      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: static;
      display: flex;
    }

    background-color: $light-blue-800;

    .message {
      margin-bottom: 24px;
    }

    .buttons {
      display: grid;
      gap: 0 16px;
      grid-template-columns: 1fr 1fr;

      &.solo {
        grid-template-columns: 1fr;
      }

      button {
        cursor: pointer;
        padding: 8px;
        border: none;
        color: $white;
        background-color: $blue;
      }
    }
  }
}
