@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: stretch;
  align-items: center;

  font-size: x-large;
  background-color: black;
  color: $light-blue-800;

  > * {
    animation: fadeIn 0.6s;
  }
}

.loadingBar {
  margin: 20px 0;
  width: 250px;

  @include not-phone {
    margin: 20px;
    width: 300px;
  }

  height: 32px;
  background-color: $blue-600;
  overflow: hidden;

  .loadingBarValue {
    width: 100%;
    height: 100%;
    background-color: $blue-100;
    animation: Loader 1s infinite linear;
    transform-origin: 0% 50%;
  }
}

@keyframes Loader {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

// The slow start is to avoid flicker of widgets should loading only take a tiny amount of time
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
