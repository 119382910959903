@import './colours.scss';

.root {
  font-family: 'Lato', sans-serif;
  background-color: $dark-background;
  color: $light-text;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a,
  a:visited,
  a:active,
  a:focus {
    color: $light-hyperlink;
  }
}
