@import 'theme/colours.scss';
@import 'theme/screen.scss';

.header {
  background-color: $light-background;
  color: $text-on-light-background;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  padding: 16px;

  a,
  a:visited,
  a:active,
  a:focus {
    color: $text-on-light-background;
    text-decoration: none;
  }

  h1 {
    font-size: xx-large;

    @include not-phone {
      font-size: 2em;
    }
  }
}
