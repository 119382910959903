@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  user-select: none;
  background-color: $light-blue-800;
  color: $blue-600;
  display: flex;
  flex-direction: column;
}

.top {
  padding: 16px;

  color: $light-blue-800;
  background: $purple;

  .home {
    width: 24px;
    height: 24px;
  }

  .icon {
    .primary {
      fill: $light-blue-700;
    }

    .secondary {
      fill: $light-blue-800;
    }
  }

  > .buttons {
    display: flex;
    justify-content: space-between;
    align-content: stretch;
    padding-bottom: 16px;

    @include desktop {
      padding-bottom: 32px;
    }
    font-size: smaller;
  }

  > .header {
    margin: 0;
    padding-bottom: 4px;
    padding-right: 48px;

    + span {
      font-size: small;
    }
  }
}

.section {
  h3 {
    padding-left: 8px;
    font-size: medium;
  }

  ul {
    padding: 0;
  }

  .item {
    padding: 16px;
    list-style-type: none;

    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;

    @include desktop {
      font-size: larger;
    }

    &:hover {
      background-color: $blue-100;
      color: $light-blue-800;
    }

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 24px;

      .primary {
        fill: $light-blue-700;
      }

      .secondary {
        fill: $blue-100;
      }
    }

    &.selected {
      background-color: $blue-200;
      color: $light-blue-800;
      cursor: default;
    }
  }

  + .section {
    border-top: solid 1px $blue-500;
  }
}
