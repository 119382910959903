@import 'theme/palette.scss';
@import 'theme/z-index.scss';

$animLength: 90ms;
$overlay-background-colour: rgba($blue-600, 90%);

.overlay {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  background-color: $overlay-background-colour;
  z-index: $z-overlay;

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;

    max-width: 90%;
    height: 100vh;

    display: flex;
  }
}

.animEnter {
  background-color: transparent;

  .menu {
    transform: translateX(-100%);
  }
}
.animEnterActive {
  background-color: $overlay-background-colour;
  transition: background-color $animLength ease-out;

  .menu {
    transform: translateX(0%);
    transition: transform $animLength ease-out;
  }
}
.animExit {
  background-color: $overlay-background-colour;

  .menu {
    transform: translateX(0%);
  }
}
.animExitActive {
  background-color: transparent;
  transition: background-color $animLength ease-in;

  .menu {
    transform: translateX(-100%);
    transition: transform $animLength ease-in;
  }
}
