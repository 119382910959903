.root {
  a,
  a:hover,
  a:active {
    text-decoration: none;
  }
}

.card {
  background-color: white;
  color: black;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;

  width: 400px;
  height: 200px;
}

.section {
  margin: 0 0 48px;
}
