@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  border: 5px solid $blue-100;
  color: white;
  background-color: black;
  user-select: none;
  font-family: 'Azeret Mono', monospace;
  text-align: center;
  font-size: larger;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;

  grid-template-rows: 1fr;
  gap: 0px 0px;
  align-items: center;

  .menuButton {
    display: flex;
  }
}
