@import 'theme/palette.scss';
@import 'theme/screen.scss';

.root {
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 100;
  filter: drop-shadow(0 0 0.5rem $blue-300);
  @include phone {
    right: 8px;
  }
}
