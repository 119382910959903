@import 'theme/palette.scss';
@import 'theme/screen.scss';

$grid-line-color: $blue-600;
$grid-line-size: 2px;

$regular-background: $blue-300;
$alternate-background: $blue-400;

$rank-text-color: $light-blue-700;
$payout-color: $light-blue-800;

$winning-payout-color: $purple;
$winning-background-color: $light-blue-800;

.root {
  border: $grid-line-size $grid-line-color solid;
  font-size: small;

  @include desktop {
    font-size: larger;
    border: (3 * $grid-line-size) $grid-line-color solid;
  }

  background-color: $grid-line-color;
  display: grid;
  grid-template-columns: 3fr repeat(5, 1fr);

  gap: 0 $grid-line-size;
  grid-auto-flow: row;

  font-family: 'Azeret Mono', monospace;
  font-size: small;
  text-transform: uppercase;

  > :nth-child(12n + 1),
  > :nth-child(12n + 2),
  > :nth-child(12n + 3),
  > :nth-child(12n + 4),
  > :nth-child(12n + 5),
  > :nth-child(12n + 6) {
    background: $alternate-background;
  }

  > * {
    background-color: $regular-background;
    padding: 2px 4px;

    @include tablet {
      padding: 4px 6px;
    }

    @include desktop {
      padding: 8px;
    }
  }

  .rank {
    color: $rank-text-color;
    white-space: nowrap;
  }

  .win {
    animation: blink 0.5s infinite alternate;
  }

  .payout {
    text-align: center;
    color: $payout-color;

    &.selected {
      color: $winning-payout-color;
      background-color: $winning-background-color;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  49.99% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
