.root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;

  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
}
