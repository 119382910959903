$stop-a: 0%;
$stop-b: 48%;
$stop-c: 52%;
$stop-d: 100%;

$button-normal-outer: rgba(0, 0, 90, 1);
$button-normal-inner: rgba(0, 125, 191, 1);

$button-selected-outer: rgba(40, 0, 0, 1);
$button-selected-inner: rgba(255, 20, 0, 1);

$button-disabled-outer: rgba(25, 25, 25, 1);
$button-disabled-inner: rgba(140, 140, 140, 1);

.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  > button {
    border: none;
    cursor: pointer;

    &:not(:first-child) {
      border-left: rgba(255, 255, 255, 0.25) 1px solid;
    }

    &:not(:last-child) {
      border-right: rgba(255, 255, 255, 0.25) 1px solid;
    }

    padding: 10px 0;

    color: white;

    background: linear-gradient(
      0deg,
      $button-normal-outer $stop-a,
      $button-normal-inner $stop-b,
      $button-normal-inner $stop-c,
      $button-normal-outer $stop-d
    );

    &:active {
      background: linear-gradient(
        0deg,
        $button-selected-outer $stop-a,
        $button-selected-inner $stop-b,
        $button-selected-inner $stop-c,
        $button-selected-outer $stop-d
      );
    }

    &:disabled {
      cursor: default;
      background: linear-gradient(
        0deg,
        $button-disabled-outer $stop-a,
        $button-disabled-inner $stop-b,
        $button-disabled-inner $stop-c,
        $button-disabled-outer $stop-d
      );
    }
  }
}
